import React from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useHistory } from 'react-router';

// interface JobCardProps {
//   id: number;
//   title: string;
//   description: string;
//   linkText: string;
//   linkUrl: string;

//   jobLocation: string;
//   jobResponsibilities: string;
//   jobRequirements: string;
// }

// const JobCard: React.FC<JobCardProps> = ({
//   title,
//   description,
//   linkText,
//   linkUrl,
 
//   jobLocation,
//   jobResponsibilities,
//   jobRequirements
// }) => {
//   const requirementList = jobRequirements
//   .split('•') 
//   .filter((item) => item.trim() !== '');

//   return (

//   <div className="mb-6 border-b pb-4">
//     <h2 className="text-lg my-5 font-bold capitalize">{title}</h2>
//     <p className="mt-2">
//       <span className=' font-bold'>Job Summary:</span> &nbsp;
//       {description}
//     </p>
//     <p className="mt-2">
//       <span className=' font-bold'>Key Responsibilities:</span>&nbsp;
//       {jobResponsibilities}
//     </p>
//     <div className="mt-2">
//         <span className="font-bold">
//           Competencies (skills, experience, and knowledge):
//         </span>
//         <ul className="list-disc list-inside mt-2">
//     {requirementList.map((requirement) => (
//       <li key={requirement.trim()}>{requirement.trim()}</li>
//     ))}
//   </ul>
//       </div>

//     <p className="mt-2 font-semibold">Location: {jobLocation}</p>
  

//     <div className="mt-4">
//       <button
//         type="button"
//         className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-all"
//         onClick={() => window.open(linkUrl, '_blank', 'noopener noreferrer')}
//       >
//         Apply Here
//       </button>
//     </div>
//   </div>
// );
// }
// interface JobVacancy {
//   id: number;
//   title: string;
//   description: string;
//   linkText: string;
//   linkUrl: string;
//   jobLocation: string;
//   jobResponsibilities: string;
//   jobRequirements: string;
// }

// Temporary 
interface JobVacancy {
  id: number;
  title: string;
}

const CareerListing: React.FC = () => {
  const history = useHistory();
// Temporary starts
  const jobVacancies: JobVacancy[] = [
    { id: 1, title: 'General Manager Operations' },
    { id: 2, title: 'Audit, Risk & Control Manager' },
    { id: 3, title: 'General Manager Business Services' },
    { id: 4, title: 'Project Execution & Facilities Manager' },
    { id: 5, title: 'Business Development Manager' },
    { id: 6, title: 'Branding & Digital Marketing Manager' },
    { id: 7, title: 'Talent Acquisition & Engagement Lead' },
    { id: 8, title: 'Compensation & Benefits Analyst' },
    { id: 9, title: 'Planning & Strategy Analyst' },
  ];


  return (
    <div className="w-full">
      <div className="container mx-auto">
        <div className="m-2 p-2 lg:m-10 lg:p-4">
          {/* Back Button */}
          <button
            type="button"
            className="my-6 flex items-center text-black hover:underline"
            onClick={() => history.push('/career-with-gigl')}
          >
            <HiOutlineArrowNarrowLeft className="text-2xl mr-2" />
            Back
          </button>

          {/* Job Listings */}
          <div className="mb-6">
            <h1 className="text-2xl font-bold mb-4">Job Vacancies</h1>
            <ul className="list-disc pl-6 space-y-2">
              {jobVacancies.map((job) => (
                <li key={job.id} className="text-lg">
                  {job.title}
                </li>
              ))}
            </ul>
          </div>

          {/* Single Apply Button */}
          <div className="mt-6">
            <button
              type="button"
              className="bg-black text-white px-6 py-3 rounded transition-all"
              onClick={() =>
                window.open(
                  'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
                  '_blank',
                  'noopener noreferrer'
                )
              }
            >
              Apply Here
            </button>
          </div>
        </div>
      </div>
    </div>
  );






// Temporary ends

  // const jobVacancies: JobVacancy[] = [
  //   {
  //     id: 1,
  //     title: 'General Manager Operations',
  //     description:
  //       'We are looking for a skilled software engineer to join our team. Responsibilities include developing high-quality software solutions, debugging, and collaborating with cross-functional teams.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos, Nigeria  (Onsite)',
  //     jobResponsibilities: 'dsfghjkjlk',
  //     jobRequirements: 'fghj',
  //   },
  //   {
  //     id: 2,
  //     title: 'Audit, Risk & Control Manager',
  //     description:
  //       'Join our team as a Product Manager to drive the development and delivery of our product roadmap. Strong leadership and communication skills required.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos (Onsite)',
  //     jobResponsibilities: 'dsfghjkjlk',
  //     jobRequirements: 'fghj',
  //   },
  //   {
  //     id: 3,
  //     title: 'General Manager Buisness Services',
  //     description:
  //       'We are seeking a talented UI/UX Designer to create amazing user experiences. The ideal candidate should have an eye for clean and artful design.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos, Nigeria (Onsite)',
  //     jobResponsibilities: 'dsfghjkjlk',
  //     jobRequirements: 'fghj',
  //   },
  //   {
  //     id: 4,
  //     title: 'Project Execution & Facilities Manager',
  //     description:
  //       'We are seeking a talented UI/UX Designer to create amazing user experiences. The ideal candidate should have an eye for clean and artful design.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos (Onsite)',
  //     jobResponsibilities: 'dsfghjkjlk',
  //     jobRequirements: 'fghj',
  //   },
  //   {
  //     id: 5,
  //     title: 'Business Develoment Manager',
  //     description:
  //       'We are seeking a talented UI/UX Designer to create amazing user experiences. The ideal candidate should have an eye for clean and artful design.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos, Nigeria (Onsite)',
  //     jobResponsibilities: 'dsfghjkjlk',
  //     jobRequirements: 'fghj',
  //   },
  //   {
  //     id: 6,
  //     title: 'Branding & Digital Marketing Manager',
  //     description:
  //       'We are looking for an experienced Brand & Digital Marketing Manager to lead and enhance our brand presence across digital platforms. This role involves developing and executing brand strategies, managing multi-channel marketing campaigns (social media, SEO, PPC, email), and overseeing content creation. The manager will utilize data-driven insights to optimize performance, lead a creative team, and ensure brand consistency. The position also involves driving social media strategies, increasing engagement, and staying up-to-date with industry trends to achieve business objectives.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos, Nigeria (Onsite)',
  //     jobResponsibilities: 'The Brand & Digital Marketing Manager will be responsible for developing and executing a comprehensive brand strategy, ensuring consistency across all communication channels. They will lead multi-channel digital marketing campaigns, including social media, email, SEO, and PPC, while overseeing content marketing efforts. This role requires analyzing campaign performance, utilizing data-driven insights to refine strategies, and staying updated on industry trends. The manager will also lead a creative team, collaborate with cross-functional teams, and drive innovative marketing campaigns to enhance brand presence and achieve business goals. Additionally, they will monitor online reputation and manage social media strategies to increase engagement and followers.',
  //     jobRequirements: "• Education: Bachelor’s degree in Marketing, Business Administration, Communications, or a related field (or equivalent experience). • Experience: Proven experience (6+ years) in brand management and digital marketing, with a strong portfolio of successful campaigns. • Excellent communication and presentation skills. • Strong team-building and leadership capabilities. • Analytical mindset with the ability to interpret data and make informed decisions. • Creative problem-solving skills, thriving in a fast-paced environment. • Expertise in digital marketing tools (Google Analytics, Google Ads, Facebook Ads Manager, etc.).  • Proficiency in SEO/SEM strategies and techniques.  •  Strong understanding of branding principles, consumer behavior, and market trends. • Basic HTML/CSS knowledge. • Familiarity with graphic design tools (Adobe Creative Suite) is a plus.",
  //   },
  //   {
  //     id: 7,
  //     title: 'Talent Acquisition & Engagement Lead',
  //     description:
  //       "The Recruitment & Engagement Lead will drive strategic and operational talent acquisition efforts to meet the workforce needs of GIGL,  focusing on both white-collar and blue-collar roles. This role ensures effective hiring practices, fosters employee engagement, and supports workforce optimization to align with the company’s goals. The Lead will also  oversee the Employee Services Officer, ensuring seamless execution of HR support functions",
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos (Onsite)',
  //     jobResponsibilities: 'dsfghjkjlk',
  //     jobRequirements: 'fghj',
  //   },
  //   {
  //     id: 8,
  //     title: 'Compensation & Benefits Analyst',
  //     description:
  //       "We are seeking a skilled Compensation & Benefits Officer to develop, administer, and manage our organization’s compensation and benefits programs. The ideal candidate will ensure employee compensation remains competitive, aligned with market trends, and compliant with company policies and legal regulations.",
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos (Onsite)',
  //     jobResponsibilities: 'Responsible for managing payroll, designing performance-based incentive programs, administering employee benefits, and ensuring compliance with labor laws and company policies. Expertise in analyzing compensation data, preparing reports, and optimizing benefits offerings based on employee feedback and market trends. Skilled in educating employees on programs, maintaining accurate records, and supporting audits to align with organizational goals.',
  //     jobRequirements: "• Education: Bachelor's or Master’s degree in Human Resources Management or related fields. • Experience: 2–3 years of experience in a Payroll Management role. • Certification: Professional certification in Human Resource Management (e.g., CIPM, PHRi, PMI). • Proficiency in HRIS and payroll software. • Comprehensive understanding of tax regulations and labor laws.  •  Advanced Microsoft Excel skills including expertise in pivot tables and data visualization. • Familiarity with job evaluation and market pricing tools. • Hands-on experience with benefits administration platforms.  • Exceptional analytical and problem-solving abilities.  • Strong communication and interpersonal skills. • Meticulous attention to detail with excellent organizational capabilities. • High ethical standards and the ability to handle sensitive information with confidentiality. • Proven negotiation and influencing skills. ",
  //   },
  //   {
  //     id: 9,
  //     title: 'Planning & Strategy Analyst',
  //     description:
  //       'We are looking for a Planning & Strategy Analyst to support the organization’s strategic objectives through data analysis and actionable insights. The role involves conducting market research, developing business plans, tracking performance metrics, and identifying growth opportunities. The analyst will collaborate across departments, manage risk assessments, and contribute to process improvements to drive long-term success.',
  //     linkText: 'Apply',
  //     linkUrl: 'https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true',
     
  //     jobLocation: 'Lagos (Onsite)',
  //     jobResponsibilities: "The Planning & Strategy Analyst will support the organization’s strategic objectives by analyzing industry trends, market conditions, and competitive landscapes to provide actionable insights. The role involves assisting in the development of business plans, tracking the performance of strategic initiatives, and identifying areas for improvement. The analyst will gather and interpret data to create financial and operational models, prepare reports, and collaborate with cross-functional teams to align strategies with organizational goals. This role also includes assessing risks, improving planning processes, and ensuring the successful execution of strategic initiatives. Strong analytical, communication, and project management skills are essential.",
  //     jobRequirements: "• Education: Bachelor’s degree in Business Administration, Economics, Finance, or a related field.  • Experience: 2–5 years of experience in strategic planning, business analysis, management consulting, or a similar role.  • Certification: Professional certification in Business Analysis and Project Management (e.g., CBAP, PMP, CSPS) is preferred. • Proficiency in data analysis tools (e.g., Excel, Tableau, Power BI) and financial modeling; experience with CRM or ERP systems is a plus. • Strong project management skills with the ability to prioritize tasks and meet deadlines. • Excellent communication skills, with the ability to convey complex information to non-technical audiences. • Strong analytical and problem-solving skills, with a focus on actionable insights. • Ability to work collaboratively with cross-functional teams and adapt to shifting business priorities. • High attention to detail and ability to manage multiple tasks effectively.",
  //   },

  // ];

  // return (
  //   <div className="w-full">
  //     <div className="container mx-auto">
  //       <div className=" m-2 p-2 lg:m-10 lg:p-4">
  //         <button
  //           type="button"
  //           className="my-6"
  //           onClick={() => history.push('/career-with-gigl')}
  //         >
  //           <HiOutlineArrowNarrowLeft className="inline-flex text-2xl" />
  //           Back
  //         </button>
  //         <div>
  //           {jobVacancies.map((job) => (
  //             <JobCard key={job.id} {...job} />
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  
};
export default CareerListing;
