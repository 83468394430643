import React,{FC} from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/common/PageHeader';
import TwoColumnContent from '../../components/ContentBlades/TwoColumnContent';
import careerbanner from '../../assets/images/careerbanner.png';


const Career: FC = () => (
    
  <div className="w-full">
    <div className="container mx-auto">
    <PageHeader
      title="Current vacancies"
      description="Would you like to join our team?"
    />
    <TwoColumnContent bannerUrl={careerbanner}>
      <>
        <p className='mb-5'>
          We're excited about your interest in joining GIG Logistics! Here, we
          don't just deliver parcels; we build careers, nurture talent, and
          create opportunities for growth. At GIGL, we believe our people are
          our greatest strength, and we empower them to achieve personal and
          professional success through meaningful work, training, and
          mentorship.
        </p>
        
        <p className='mb-5'>
          Are you innovative, passionate, and driven to make an impact? Whether
          your expertise lies in logistics, customer service, operations,
          technology, sales, marketing, or beyond, GIG Logistics is the place
          where you can thrive.
        </p>

        <p className='mb-5'>
          We're on the lookout for individuals who embrace challenges, think
          outside the box, and are eager to grow into future leaders.
        </p>

        <p className="mb-5">
          <strong>What We Offer:</strong>
          <ol>
            <li> • Competitive Salary: A competitive salary and benefits package</li>
            <li> • Opportunities for Growth: Opportunities for career growth and professional development</li>
            <li> • Dynamic Work Enviornment: A dynamic and supportive work environment </li>
          </ol>
        </p>
        <div className="my-4 w-full flex text-center lg:my-3 md:my-3">
             <Link
                to="/career-with-gigl-listing"
                className="border border-secondary w-full sm:w-auto bg-secondary text-white py-3 px-14 font-bold text-lg rounded"
              >
                Proceed
              </Link>
          </div>
      </>
    </TwoColumnContent>
    </div>
  </div>
);

export default Career;
